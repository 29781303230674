<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="8000"
      top
      :color="color" dark
    >
      {{text}}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-row>
      <v-col>
        <v-card class="pt-1 pa-3">
          <v-card-title primary-title>
           <h3> Direcciones de envío <v-icon large right>airport_shuttle</v-icon> </h3>
          </v-card-title>
          <v-divider class="mb-1"></v-divider>
          <v-row justify="space-between">
            <v-col cols="12" xs="12" sm="4" >
              <v-text-field
                label="Nombre de la dirección"
                filled
                shaped
                v-model="nombre"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4" >
              <v-text-field
                label="Calle"
                filled
                shaped
                v-model="calle"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="2" >
              <v-text-field
                label="Número Ext."
                filled
                shaped
                v-model="numext"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3" >
              <v-text-field
                label="Colonia"
                filled
                shaped
                v-model="colonia"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3" >
              <v-text-field
                label="Ciudad"
                filled
                shaped
                v-model="ciudad"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3" >
              <v-text-field
                label="Estado"
                filled
                shaped
                v-model="estado"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="2" >
              <v-text-field
                label="CP"
                filled
                shaped
                v-model="cp"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4" >
              <v-text-field
                label="Encargado"
                filled
                shaped
                v-model="encargado"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4" >
              <v-text-field
                label="Teléfono"
                filled
                shaped
                v-model="telefono"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" @click="validaInfo">Guardar</v-btn>
            <v-btn color="orange" dark :to="{name:'envios'}">Regresar</v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      snackbar: false,
      color: 'success',
      text: '',
      encargado: '',
      calle: '',
      numext: '',
      colonia: '',
      ciudad: '',
      estado: '',
      cp: '',
      numclisuc: '',
      nombre: '',
      telefono: ''
    }
  },

  methods: {
    ...mapActions('direccion', ['addDireccion']),

    validaInfo () {
      if (this.encargado == '' || this.calle == '' || this.numext == '' || this.colonia == '' || this.ciudad == '' ||
          this.estado == '' || this.cp == '' || this.nombre == '' || this.telefono == '') {
        this.color = 'orange'
        this.text = 'Llena todos los campos'
        this.snackbar = true
      } else {
        this.agregar()
      }
    },

    agregar () {
      var payload = {
        encargado: this.encargado,
        calle: this.calle,
        numext: this.numext,
        colonia: this.colonia,
        ciudad: this.ciudad,
        estado: this.estado,
        cp: this.cp,
        numcli: this.getdatosUsuario.numcli,
        numclisuc: '     ' + this.getdatosUsuario.numcli,
        nombre: this.nombre,
        telefono: this.telefono
      }

      this.addDireccion(payload).then(response => {
        if (response == true) {
          this.color = 'success'
          this.text = 'Se agrego la dirección correctamente'
          this.snackbar = true
          var me = this
          setTimeout(function () { me.$router.push({ name: 'envios' }) }, 2000)
        } else {
          this.color = 'error'
          this.text = 'No se pudo agregar la dirección'
          this.snackbar = true
        }
      })
    }
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario'])

  }

}
</script>
